export const QR_CODE_APP = {
  android: {
    dark: 'assets/img/qr-android-app-dark.png',
    light: 'assets/img/qr-android-app-light.png',
  },
  ios: {
    dark: 'assets/img/qr-ios-app-dark.png',
    light: 'assets/img/qr-ios-app-light.png',
  },
};

export const APP_STORE_URL = {
  android: 'https://play.google.com/store/apps/details?id=io.dextools.app',
  ios: 'https://apps.apple.com/us/app/dextools/id1628834724',
};
