import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideMenuMobileService {
  private _showMenu = false;
  private readonly _toggleMenu$ = new Subject<boolean>();
  public toggleMenuObs = this._toggleMenu$.asObservable();

  public constructor() {
    this._toggleMenu$.next(this._showMenu);
  }

  public toggleMenu() {
    this._showMenu = !this._showMenu;
    this._toggleMenu$.next(this._showMenu);
  }
}
